export function randomNumber(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

export function generateHex(length: number) {
  let hex = "";

  for(let i = 0; i < length; i++) {
    hex += randomNumber(0, 15).toString(16);
  }

  return hex;
}

export const asyncSleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export function scrollTo(querySelector: string) {
  const element = document.querySelector(querySelector);

  if(!element) {
    throw new Error('No such element found');
  }

  const rect = element.getBoundingClientRect();

  window.scrollTo({top: rect.top - 100, behavior: 'smooth'});
}

export function shuffleArray<Type>(array: Type[]) {
  let currentIndex = array.length;
  let randomIndex;

  while(currentIndex !== 0) {

    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]
    ];
  }

  return array;
}

export function getPrice(prices: number[], hoursAmount: number) {
  let sum = 0;
  let _amount = hoursAmount;

  const _prices = [0, ...prices];

  while(_amount >= 0) {

    if(_amount > _prices.length - 1) {
      _amount -= _prices.length - 1;
      sum += _prices[_prices.length - 1];
    }
    else {
      sum += _prices[_amount];
      break;
    }
  }

  return sum;
}

export function getNoun(number: number, one: string, two: string, five: string) {
  let n = Math.abs(number);
  n %= 100;
  if(n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if(n === 1) {
    return one;
  }
  if(n >= 2 && n <= 4) {
    return two;
  }
  return five;
}
