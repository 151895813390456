import React from "react";

export function IconsCollection() {
  /*
   * Icons here
   * I take icons from iconmonstr.com but you can them from anywhere (for example: svgrepo.com)
   *  */

  return (
    <svg
      viewBox="0 0 24 24"
      style={{display: "none"}}
    >
      <symbol
        id="menu-right-lined"
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
      >
        <path
          d="m11 16.745c0-.414.336-.75.75-.75h9.5c.414 0 .75.336.75.75s-.336.75-.75.75h-9.5c-.414 0-.75-.336-.75-.75zm-9-5c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm4-5c0-.414.336-.75.75-.75h14.5c.414 0 .75.336.75.75s-.336.75-.75.75h-14.5c-.414 0-.75-.336-.75-.75z"
          fillRule="nonzero"
        />
      </symbol>

      <symbol
        id="shape-10"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <path d="M19 0h-14c-2.76 0-5 2.239-5 5v14c0 2.76 2.239 5 5 5h14c2.759 0 5-2.239 5-5v-14c0-2.76-2.239-5-5-5m0 2c1.66 0 3 1.345 3 3v14c0 1.654-1.338 3-3 3h-14c-1.654 0-3-1.339-3-3v-14c0-1.658 1.342-3 3-3h14z" />
      </symbol>

      <symbol id="checkbox-9">
        <path d="M10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591zm-5.041-15c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z" />
      </symbol>

      <symbol id="circle-2">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z" />
      </symbol>

      <symbol id="checkbox-28">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 6c-3.313 0-6 2.687-6 6s2.687 6 6 6c3.314 0 6-2.687 6-6s-2.686-6-6-6z" />
      </symbol>

      <symbol id="arrow-65">
        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
      </symbol>

      <symbol id="arrow-63">
        <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
      </symbol>

      <symbol id="arrow-64">
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </symbol>

      <symbol
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        id="x-mark-circle-lined"
      >
        <path
          d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
          fillRule="nonzero"
        />
      </symbol>

      <symbol id="check-mark-circle-lined">
        <path d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm0 1.5c-4.69 0-8.498 3.807-8.498 8.497s3.808 8.498 8.498 8.498 8.497-3.808 8.497-8.498-3.807-8.497-8.497-8.497zm-5.049 8.886 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z" />
      </symbol>

      <symbol
        id="error-lined"
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
      >
        <path d="m12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-1.5c-4.69 0-8.497-3.808-8.497-8.498s3.807-8.497 8.497-8.497 8.498 3.807 8.498 8.497-3.808 8.498-8.498 8.498zm0-6.5c-.414 0-.75-.336-.75-.75v-5.5c0-.414.336-.75.75-.75s.75.336.75.75v5.5c0 .414-.336.75-.75.75zm-.002 3c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z" />
      </symbol>

      <symbol
        id="info-lined"
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
      >
        <path
          d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 6.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
          fillRule="nonzero"
        />
      </symbol>

      <symbol id="phone-2">
        <path d="M6.176 1.322l2.844-1.322 4.041 7.89-2.724 1.341c-.538 1.259 2.159 6.289 3.297 6.372.09-.058 2.671-1.328 2.671-1.328l4.11 7.932s-2.764 1.354-2.854 1.396c-7.862 3.591-19.103-18.258-11.385-22.281zm1.929 1.274l-1.023.504c-5.294 2.762 4.177 21.185 9.648 18.686l.971-.474-2.271-4.383-1.026.5c-3.163 1.547-8.262-8.219-5.055-9.938l1.007-.497-2.251-4.398z" />
      </symbol>

      <symbol id="email-4">
        <path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z" />
      </symbol>

      <symbol id="wireless-9">
        <path d="M4.504 13.572l-1.505-1.489c2.201-2.805 5.413-4.583 9.001-4.583s6.8 1.779 9 4.583l-1.504 1.489c-1.835-2.338-4.512-3.822-7.496-3.822s-5.661 1.484-7.496 3.822zm7.496.678c1.791 0 3.397.891 4.498 2.293l1.502-1.488c-1.467-1.869-3.608-3.055-6-3.055s-4.533 1.186-6 3.055l1.502 1.488c1.101-1.402 2.707-2.293 4.498-2.293zm0 2.25c-1.196 0-2.258.602-2.99 1.536l2.99 2.964 2.99-2.963c-.732-.935-1.794-1.537-2.99-1.537zm0-11.25c4.179 0 7.927 2.078 10.495 5.351l1.505-1.491c-2.935-3.739-7.217-6.11-12-6.11s-9.065 2.371-12 6.11l1.505 1.491c2.568-3.273 6.316-5.351 10.495-5.351z" />
      </symbol>

      <symbol id="airport-6" fillRule="evenodd" clipRule="evenodd">
        <path d="M8.801 9l-2.602-6h3.688l6.182 6c1.373 0 2.93-.012 4.303.001 1.622.057 3.628 1.465 3.628 2.996 0 1.557-2.038 2.97-3.67 3.003h-4.236l-6.208 6h-3.658l2.594-6h-3.506l-2.164 2h-3.152l1.742-4.998-1.742-5.002h3.142l2.149 2h3.51zm3.034 2l-7.205-.004-2.017-1.996 1.26 2.998-1.283 3.002 1.997-2h7.246l-2.472 5.727 5.924-5.727c1.633 0 3.452.077 5.082-.002.791-.058 1.615-.809 1.615-.996 0-.211-.915-.995-1.694-1.002h-5.03l-5.909-5.735" />
      </symbol>

      <symbol id="smartphone-13" fillRule="evenodd" clipRule="evenodd">
        <path d="M13.563 24h-11.563c-1.104 0-2-.896-2-2v-20c0-1.104.896-2 2-2h14c1.104 0 2 .896 2 2v4h-2v-3h-14v17h11v2.5c0 .524.182 1.175.563 1.5zm8.937-17c.828 0 1.5.672 1.5 1.5v14c0 .826-.671 1.5-1.5 1.5h-7c-.829 0-1.5-.675-1.5-1.5v-14c0-.827.673-1.5 1.5-1.5h7zm-13.5 14c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm10 2c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm4-3v-10.024h-8v10.024h8zm-3.5-11c.275 0 .5-.224.5-.5s-.225-.5-.5-.5h-1c-.276 0-.5.224-.5.5s.224.5.5.5h1z" />
      </symbol>

      <symbol id="fast-food-22" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M9.46 11.039l-.48-4.039h-.98v-1.382c.87 0 2-.697 2-1.618h6.185l2.113-4 1.359.634-1.817 3.366h4.199c0 .922 1.092 1.618 1.961 1.618v1.382h-1l-2 17s-15.839.007-17.094-.005c-2.168-.068-3.017-1.656-3.041-3.743-.569-.472-.909-1.485-.861-2.409.044-.872.467-1.674 1.139-2.203.669-3.206 3.591-4.594 7.28-4.64.352 0 .698.013 1.037.039zm-6.466 9.957h-.002c.031.791.267.979.954.999 1.241.013 8.514 0 9.098-.009.679-.022.92-.269.96-.943 0 0-4.384.036-11.01-.047zm8.513-9.608c2.212.616 3.855 1.986 4.344 4.247.676.529 1.101 1.333 1.145 2.208.048.931-.437 1.866-.996 2.418.018.643-.061 1.23-.235 1.739h3.5l1.735-15h-10l.507 4.388zm2.493 5.612l-3.831.005c-.962.007-1.433 1.342-3.068 1.326-1.732-.018-1.855-1.313-2.723-1.309l-1.454-.022c-.54.046-.899.472-.923.943-.026.523.354 1.033 1.005 1.053h11.008c.645-.034 1.01-.539.985-1.053-.027-.49-.438-.92-.999-.943zm-.52-2.006c-.971-1.579-3.186-1.994-5.042-1.994-1.859.025-3.981.417-4.942 1.997l1.098.021c1.443.009 1.549 1.487 2.517 1.482.839-.004 1.404-1.492 2.834-1.494.141-.001 2.675-.012 3.535-.012z"
        />
      </symbol>

      <symbol id="school-11" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M9.082 2.303c.299-1.286 1.468-2.303 2.918-2.303 1.487 0 2.639 1.057 2.923 2.316 1.701.422 3.117 1.306 4.056 2.81.748 1.199 1.054 2.393 1.553 6.089 1.002.265 1.634.865 1.912 1.76.263.846.516 3.591.554 5.029.035 1.473-.395 2.64-1.881 3.131l-.01.003c-.498 3.078-3.527 2.848-4.527 2.859-1.444.009-2.692-.004-4.576-.011-1.198.004-5.325.013-5.804-.016-1.984-.158-3.03-1.228-3.297-2.829-1.144-.363-1.904-1.3-1.904-2.805 0-1.27.205-3.603.482-5.056.233-1.083.886-1.775 1.994-2.067.47-3.648.804-5.189 1.868-6.588.951-1.251 2.245-1.956 3.739-2.322m-2.827 4.823c-.436 1.241-.839 4.666-.991 5.915-.331.02-.67.032-1.004.062-.688.073-.765.255-.845.727-.274 1.602-.413 3.459-.415 4.565.01.593.204.797.695.887.156.027.929.09 1.1.105.083 2.411.128 2.588 2.649 2.611 1.335.008 4.56-.011 4.56-.011 2.637.01 3.49.021 4.862.008 2.281-.022 2.273-.42 2.347-2.607.332-.029.664-.053.995-.091.836-.118.812-.542.784-1.39-.04-1.277-.184-2.807-.425-4.195-.068-.341-.178-.486-.569-.57-.274-.062-.97-.085-1.252-.102-.124-1-.548-4.579-.991-5.852-.877-2.523-3.084-3.19-5.777-3.19-2.65 0-4.843.628-5.723 3.128m11.746 10.863c-.012 1.923-.901 2.937-2.888 2.998-2.073.019-4.144.021-6.217 0-1.896-.061-2.854-1.164-2.896-2.928v-4.068h12.001v3.998zm-7-2.998h-4c0 1.036-.023 2.071.001 3.106.045 1.318.711 1.85 1.915 1.89 2.059.021 4.118.019 6.176 0 1.383-.043 1.895-.565 1.909-2.001v-2.995h-4.001v2.998c0 .551-.449 1-1 1-.552 0-1-.449-1-1v-2.998zm.446-8.196c-1.944.149-2.953.773-3.213 5.208-.062.632-.961.629-1-.019.013-.702.153-1.945.351-2.804.359-1.542 1.033-2.742 2.543-3.185.974-.286 2.781-.285 3.749 0 1.455.426 2.133 1.555 2.496 3.037.244 1 .392 2.656.366 3.016-.084.582-.895.593-.993.01-.306-3.096-.336-5.126-3.255-5.267l.676 2.335c.166.75-.405 1.455-1.166 1.455-.751 0-1.319-.688-1.171-1.43l.617-2.356zm.554 1.994c.33 0 .598.268.598.597 0 .33-.268.598-.598.598-.33 0-.597-.268-.597-.598 0-.329.267-.597.597-.597m1.791-6.683c-.328-.659-.995-1.107-1.797-1.107-.814.01-1.46.46-1.783 1.102 1.082-.133 2.448-.141 3.58.005"
        />
      </symbol>

      <symbol id="heart-filled" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
        <path
          d="m12 5.72c-2.624-4.517-10-3.198-10 2.461 0 3.725 4.345 7.727 9.303 12.54.194.189.446.283.697.283s.503-.094.697-.283c4.977-4.831 9.303-8.814 9.303-12.54 0-5.678-7.396-6.944-10-2.461z"
          fillRule="nonzero"
        />
      </symbol>

      <symbol id="x-mark-lined" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
        <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
      </symbol>

      <symbol id="smiley-thin" fillRule="evenodd" clipRule="evenodd">
        <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm-.045 17.51h-.015c-2.285 0-4.469-1.189-6.153-3.349l.789-.614c1.489 1.911 3.394 2.963 5.364 2.963h.013c1.987-.004 3.907-1.078 5.408-3.021l.791.611c-1.693 2.194-3.894 3.405-6.197 3.41zm-3.468-10.01c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm7.013 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
      </symbol>
    </svg>
  );
}

export default function Icon({icon}: {icon: string}) {
  // Render
  return (
    <svg
      viewBox="0 0 24 24"
      className="Icon"
    >
      <use xlinkHref={`#${icon}`} />
    </svg>
  );
}
