export type vRules =
  | "personName"
  | "isInteger"
  | "phoneNumber"
  | "passportSeries"
  | "passportNumber"
  | "passportIssuerCode"
  | "isDouble"
  | "isDate"
  | "isPastDate"
  | "isFutureDate"
  | "gender"
  | "comment";

const validationRules: {
  [key in vRules]: (entity: unknown) => boolean;
} = {
  personName(entity) {
    if(typeof entity !== "string") {
      return false;
    }

    return /^[А-ЯЁA-Z][а-яё\-a-z]{1,25}[а-яa-zё]$/iu.test(entity);
  },

  isInteger(entity) {
    const _entity = typeof entity === 'string' ? entity : String(entity);

    return /^-?\d+$/u.test(_entity);
  },

  phoneNumber(entity) {
    if(typeof entity !== "string") {
      return false;
    }

    return /^\+79\d{9}$/u.test(entity);
  },

  passportSeries(entity) {
    if(typeof entity !== "string") {
      return false;
    }

    return /^\d{4}$/u.test(entity);
  },

  passportNumber(entity) {
    if(typeof entity !== "string") {
      return false;
    }

    return /^\d{6}$/u.test(entity);
  },

  passportIssuerCode(entity) {
    if(typeof entity !== "string") {
      return false;
    }

    return /^\d{3}-\d{3}$/u.test(entity);
  },

  isDouble(entity) {
    const _entity = typeof entity === 'string' ? entity : String(entity);

    return /^-?\d+(\.\d+)?$/u.test(_entity);
  },

  isDate(entity) {
    if(typeof entity !== "string") {
      return false;
    }

    return String(new Date(entity)) !== "Invalid Date";
  },

  isPastDate(entity) {
    if(typeof entity !== "string" || !this.isDate(entity)) {
      return false;
    }

    return new Date(entity) <= new Date();
  },

  isFutureDate(entity) {
    if(typeof entity !== "string" || !this.isDate(entity)) {
      return false;
    }

    return new Date(entity) > new Date();
  },

  gender(entity) {
    if(typeof entity !== "string") {
      return false;
    }

    return ["male", "female"].includes(entity);
  },

  comment(entity) {
    return (
      typeof entity === "string" &&
      entity.trim().length > 0 &&
      entity.trim().length <= 200
    );
  }
};

export type ValidationRequest = {
  rule: vRules;
  entity: unknown;
};

export default function validate({rule, entity}: ValidationRequest): boolean {
  return validationRules[rule](entity);
}

export function validateMany(
  validationRequests: (ValidationRequest & {displayRuleName?: string})[]
): string[] {
  const validationMessages: string[] = [];

  for(const {rule, entity, displayRuleName} of validationRequests) {
    if(!validate({rule, entity})) {
      validationMessages.push(
        `Поле "${displayRuleName ?? rule}" заполнено не правильно`
      );
    }
  }

  return validationMessages;
}
