import {AxiosError} from "axios";
import classNames from "classnames";
import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";
import {IMaskInput} from "react-imask";
import Checkbox from "../../components/Checkbox";
import Icon from "../../components/Icon";
import Loader from "../../components/Loader";
import Select from "../../components/Select";
import axiosClient from "../../helpers/axiosClient";
import {getNoun, getPrice} from "../../helpers/misc";
import {createNotification} from "../../helpers/notifications";
import onError from "../../helpers/onError";
import {validateMany, vRules} from "../../helpers/validate";
import BookingModel from "../../models/Booking";
import CapsuleTypeModel from "../../models/CapsuleType";
import styles from './index.module.css';

export default function BookingPage() {

  // State
  const [capsuleTypes, setCapsuleTypes] = useState<CapsuleTypeModel[]>([]);
  const [selectedCapsuleType, setSelectedCapsuleType] = useState<string | null>(null);
  const [bookingState, setBookingState] = useState({
    step: 0,
    isPending: false,
    privacyAgreement: true,

    bookingDate: DateTime.now().toISODate(),
    bookingTime: DateTime.now().toFormat('HH:mm'),
    daysAmount: 1,

    checkInAt: '',
    checkOutAt: '',

    firstName: '' as BookingModel['firstName'],
    middleName: '' as BookingModel['middleName'],
    lastName: '' as BookingModel['lastName'],
    birthDateISO: '',
    birthDate: '',

    phoneNumberFormatted: '',
    phoneNumber: '' as BookingModel['phoneNumber'],
    raceNumber: '' as BookingModel['raceNumber'],

    passportSeries: '' as BookingModel['passportSeries'],
    passportNumber: '' as BookingModel['passportNumber'],
    passportIssuerCode: '' as BookingModel['passportIssuerCode'],
    passportIssuedAt: '' as BookingModel['passportIssuedAt'],
    passportIssuedAtISO: '',
    passportIssuedBy: '' as BookingModel['passportIssuedBy'],
    legalAddress: '' as BookingModel['legalAddress']
  });

  const [agreementHtml, setAgreementHtml] = useState<string>('');

  // Check booking case function
  function checkBookingCase() {

    const {bookingDate, daysAmount} = bookingState;

    // Check if pending
    if(bookingState.isPending) return;
    setBookingState({...bookingState, isPending: true});

    // Build ISO checkIn and checkOut
    const checkInAt = DateTime.fromJSDate(new Date(`${bookingDate}`)).toISO();
    const checkOutAt = DateTime.fromJSDate(new Date(`${bookingDate}`)).plus({day: daysAmount}).toISO();
    setBookingState((bookingState) => ({...bookingState, checkInAt, checkOutAt}));

    // if(DateTime.fromISO(checkInAt).minute % 10) {
    //   setBookingState((bookingState) => ({...bookingState, isPending: false}));
    //   return createNotification({
    //     title: "Укажите время с шагом в 10 минут",
    //     icon: "error-lined",
    //     expireDate: DateTime.now().plus({second: 5}).toISO()
    //   });
    // }

    if(!(DateTime.fromJSDate(new Date(`${bookingDate}`))) || DateTime.fromISO(checkInAt) <= DateTime.now()
    .minus({day: 2})
    .endOf('day')) {
      setBookingState((bookingState) => ({...bookingState, isPending: false}));
      return createNotification({
        title: "Убедитесь, что дата заселения указана на будущее время",
        icon: "error-lined",
        expireDate: DateTime.now().plus({second: 5}).toISO()
      });
    }

    // Check if available to booking
    axiosClient.get(`/checkBooking?checkInAt=${encodeURIComponent(checkInAt)}&checkOutAt=${encodeURIComponent(checkOutAt)}&capsuleType=${selectedCapsuleType}`)
    .then(() => {
      setBookingState((bookingState) => ({...bookingState, step: 1}));
    })
    .catch((error) => {
      if(error instanceof AxiosError && error.response?.status === 409) {
        return createNotification({
          title: "Увы, на это время у нас мест не осталось :(",
          contents: "Вы можете выбрать другое время или сократить количество часов",
          icon: "error-lined",
          expireDate: DateTime.now().plus({second: 5}).toISO()
        });
      }

      onError(error);
    })
    .finally(() => {
      setBookingState((bookingState) => ({...bookingState, isPending: false}));
    });
  }

  function doStep1() {

    // Check if pending
    if(bookingState.isPending) return;
    setBookingState({...bookingState, isPending: true});

    // Get & validate client data
    const validationErrors = validateMany([

      ...(bookingState.raceNumber ? [
        {rule: "comment" as vRules, entity: bookingState.raceNumber, displayRuleName: "Номер рейса"},
      ] : []),

      {rule: "personName", entity: bookingState.firstName, displayRuleName: "Имя"},
      {rule: "personName", entity: bookingState.middleName, displayRuleName: "Отчество"},
      {rule: "personName", entity: bookingState.lastName, displayRuleName: "Фамилия"},
      {
        rule: "phoneNumber",
        entity: bookingState.phoneNumberFormatted.replace(/[^\d+]/ug, ''),
        displayRuleName: "Номер" +
          " телефона"
      },
    ]);
    if(validationErrors.length) {
      setBookingState((bookingState) => ({...bookingState, isPending: false}));
      return createNotification({
        title: "Ошибка",
        contents: validationErrors[0],
        icon: "error-lined",
        expireDate: DateTime.now().plus({second: 5}).toISO()
      });
    }

    // Replace phone number and go to next step
    setBookingState((bookingState) => ({
      ...bookingState,
      phoneNumber: bookingState.phoneNumberFormatted.replace(/[^\d+]/ug, ''),
      step: 2,
      isPending: false
    }));
  }

  function doStep2() {

    // Check if pending
    if(bookingState.isPending) return;
    setBookingState({...bookingState, isPending: true});

    const birthDate = new Date(bookingState.birthDateISO);
    const passportIssuedAt = new Date(bookingState.passportIssuedAtISO);

    // Get & validate client data
    const validationErrors = validateMany([
      {rule: "passportSeries", entity: bookingState.passportSeries, displayRuleName: "Серия паспорта"},
      {rule: "passportNumber", entity: bookingState.passportNumber, displayRuleName: "Номер паспорта"},
      {rule: "comment", entity: bookingState.passportIssuedBy, displayRuleName: "Кем выдан паспорт"},
      {rule: "passportIssuerCode", entity: bookingState.passportIssuerCode, displayRuleName: "Код подразделения"},
      {rule: "comment", entity: bookingState.legalAddress, displayRuleName: "Место жительства"},
      {rule: "isPastDate", entity: passportIssuedAt.toISOString(), displayRuleName: "Дата выдачи паспорта"},
      {rule: "isPastDate", entity: birthDate.toISOString(), displayRuleName: "Дата рождения"},
      {rule: "comment", entity: bookingState.legalAddress, displayRuleName: "Место жительства"},
    ]);
    if(validationErrors.length) {
      setBookingState((bookingState) => ({...bookingState, isPending: false}));
      return createNotification({
        title: "Ошибка",
        contents: validationErrors[0],
        icon: "error-lined",
        expireDate: DateTime.now().plus({second: 5}).toISO()
      });
    }

    setBookingState((bookingState) => ({
      ...bookingState,
      birthDate: birthDate.toISOString(),
      passportIssuedAt: passportIssuedAt.toISOString()
    }));

    // Download agreement
    axiosClient.get<string>(`/agreement?checkInAt=${encodeURIComponent(bookingState.checkInAt)}&checkOutAt=${encodeURIComponent(
      bookingState.checkOutAt)}&capsuleType=${selectedCapsuleType}&firstName=${bookingState.firstName}&middleName=${bookingState.middleName}&lastName=${bookingState.lastName}&birthDate=${encodeURIComponent(
      birthDate.toISOString())}&phoneNumber=${encodeURIComponent(bookingState.phoneNumber)}&passportSeries=${bookingState.passportSeries}&passportNumber=${bookingState.passportNumber}&passportIssuerCode=${bookingState.passportIssuerCode}&passportIssuedAt=${encodeURIComponent(
      passportIssuedAt.toISOString())}&passportIssuedBy=${bookingState.passportIssuedBy}&legalAddress=${bookingState.legalAddress}`)
    .then(({data}) => {
      setAgreementHtml(data);
      setBookingState((bookingState) => ({...bookingState, step: 3}));
    })
    .catch(onError)
    .finally(() => {
      setBookingState((bookingState) => ({...bookingState, isPending: false}));
    });
  }

  // Create booking function
  function createBooking() {

    // Check if pending
    if(bookingState.isPending) return;
    setBookingState({...bookingState, isPending: true});

    // Send request
    axiosClient.post<string>(`/createBooking`, {
      ...bookingState,
      capsuleType: selectedCapsuleType
    })
    .then(() => {
      setBookingState((bookingState) => ({...bookingState, step: 100}));
    })
    .catch(onError)
    .finally(() => {
      setBookingState((bookingState) => ({...bookingState, isPending: false}));
    });
  }

  // Load capsuleTypes function
  function loadCapsuleTypes() {

    return axiosClient.get<CapsuleTypeModel[]>('/capsuleTypes').then(({data}) => {

      if(!data.length) {
        throw new Error('Не удалось загрузить типы капсул');
      }

      setCapsuleTypes(data);
      setSelectedCapsuleType(data[0]._id);
    }).catch(onError);
  }

  // Load capsuleTypes
  useEffect(() => {
    loadCapsuleTypes();
  }, []);

  // Render
  return (
    <div className={classNames("page", "container", styles['booking-page'])}>
      {bookingState.step !== 100 ? (
        <h1>Анкета на залесение</h1>
      ) : null}

      {bookingState.step < 3 ? (
        <div className={styles["fill-form-text"]}>
          Заполните небольшую форму
        </div>
      ) : null}

      {bookingState.step === 0 ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            checkBookingCase();
          }}
        >
          <div className="field-wrapper">
            <div className="label">Когда вы хотите заселиться?</div>
            <input
              type="date"
              value={bookingState.bookingDate}
              onChange={({target: {value}}) => setBookingState({...bookingState, bookingDate: value})}
            />
            {/* <input */}
            {/*   type="time" */}
            {/*   value={bookingState.bookingTime} */}
            {/*   onChange={({target: {value}}) => setBookingState({...bookingState, bookingTime: value})} */}
            {/* /> */}
          </div>

          {selectedCapsuleType && capsuleTypes.length ? (
            <div className="field-wrapper">
              <Select
                options={capsuleTypes.map((capsuleType) => ({
                  value: capsuleType._id,
                  element: capsuleType.displayName
                }))}
                value={selectedCapsuleType}
                onTriggered={setSelectedCapsuleType}
              />

              <Select
                options={[
                  1,
                  2,
                  3,
                  4,
                  5,
                  6,
                  7,
                  8,
                  9,
                  10,
                  11,
                  12,
                  13,
                  14,
                  15,
                  16,
                  17,
                  18,
                  19,
                  20,
                  21,
                  22,
                  23,
                  24,
                  25,
                  26,
                  27,
                  28,
                  29,
                  30
                ].map((hoursAmount) => ({
                  value: String(hoursAmount),
                  element: `${hoursAmount} ${getNoun(hoursAmount, 'ночь', 'ночи', 'ночей')} - ${getPrice(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    capsuleTypes.find((capsuleType) => capsuleType._id === selectedCapsuleType)!.prices,
                    hoursAmount,
                  ).toLocaleString('ru')} руб`
                }))}
                value={String(bookingState.daysAmount)}
                onTriggered={(value) => setBookingState({
                  ...bookingState,
                  daysAmount: (+value)
                })}
              />
            </div>
          ) : (
            <div className="loader-container">
              <Loader color="#C28370" size={50} width={3} spinsPerSecond={1} />
            </div>
          )}

          <hr />

          <div className="field-wrapper">
            <Checkbox
              contents={<div style={{fontSize: '10pt'}}>Нажимая кнопку &laquo;Дальше&raquo;, я соглашаюсь
                с <a href="/privacy.pdf" target="_blank">политикой конфиденциальности</a></div>}
              isActive={bookingState.privacyAgreement}
              onTriggered={() => setBookingState({...bookingState, privacyAgreement: !bookingState.privacyAgreement})}
            />
          </div>

          <div className="field-wrapper">
            <button disabled={bookingState.isPending || !bookingState.privacyAgreement || !selectedCapsuleType}>
              {bookingState.isPending ? (
                <Loader color="#fff" size={25} width={3} spinsPerSecond={1.7} />
              ) : null}
              <span>Дальше</span>
            </button>
          </div>
        </form>
      ) : bookingState.step === 1 ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            doStep1();
          }}
        >
          <h2>Расскажите немного о себе</h2>

          <div className="field-wrapper">
            <input
              type="text"
              placeholder="Ваше имя..."
              value={bookingState.firstName}
              onChange={({target: {value}}) => setBookingState({...bookingState, firstName: value.trim()})}
            />
            <input
              type="text"
              placeholder="Ваше отчество..."
              value={bookingState.middleName}
              onChange={({target: {value}}) => setBookingState({...bookingState, middleName: value.trim()})}
            />
            <input
              type="text"
              placeholder="Ваша фамилия..."
              value={bookingState.lastName}
              onChange={({target: {value}}) => setBookingState({...bookingState, lastName: value.trim()})}
            />
          </div>

          <div className="field-wrapper">
            <IMaskInput
              type="text"
              mask={'{+7-(9}00)-000-00-00'}
              placeholder="Номер телефона..."
              value={bookingState.phoneNumberFormatted}
              onAccept={(value) => setBookingState({...bookingState, phoneNumberFormatted: value})}
              onChange={() => null}
            />
            {/* <input */}
            {/*   type="text" */}
            {/*   placeholder="Номер рейса (не обязательно)..." */}
            {/*   value={bookingState.raceNumber} */}
            {/*   onChange={({target: {value}}) => setBookingState({...bookingState, raceNumber: value})} */}
            {/* /> */}
          </div>

          <hr />

          <div className="field-wrapper">
            <button disabled={bookingState.isPending}>
              {bookingState.isPending ? (
                <Loader color="#fff" size={25} width={3} spinsPerSecond={1.7} />
              ) : null}
              <span>Дальше</span>
            </button>
          </div>
        </form>
      ) : bookingState.step === 2 ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            doStep2();
          }}
        >
          <h2>И последнее, укажите свои паспортные данные</h2>

          <div className="field-wrapper">
            <IMaskInput
              type="text"
              mask={'0000'}
              placeholder="Серия..."
              value={bookingState.passportSeries}
              onAccept={(value) => setBookingState({...bookingState, passportSeries: value})}
              onChange={() => null}
            />
            <IMaskInput
              type="text"
              mask={'000000'}
              placeholder="Номер..."
              value={bookingState.passportNumber}
              onAccept={(value) => setBookingState({...bookingState, passportNumber: value})}
              onChange={() => null}
            />
          </div>

          <div className="field-wrapper">
            <input
              type="text"
              placeholder="Кем выдан..."
              value={bookingState.passportIssuedBy}
              onChange={({target: {value}}) => setBookingState({...bookingState, passportIssuedBy: value})}
            />
            <IMaskInput
              type="text"
              mask={'000-000'}
              placeholder="Код подразделения..."
              value={bookingState.passportIssuerCode}
              onAccept={(value) => setBookingState({...bookingState, passportIssuerCode: value})}
              onChange={() => null}
            />
          </div>

          <div className="field-wrapper">
            <input
              type="text"
              placeholder="Место жительства..."
              value={bookingState.legalAddress}
              onChange={({target: {value}}) => setBookingState({...bookingState, legalAddress: value})}
            />
          </div>

          <div className="field-wrapper">
            <div className="field-wrapper">
              <div className="label">Дата выдачи:</div>
              <input
                type="date"
                placeholder="Дата выдачи..."
                value={bookingState.passportIssuedAtISO}
                onChange={({target: {value}}) => setBookingState({...bookingState, passportIssuedAtISO: value})}
              />
            </div>
            <div className="field-wrapper">
              <div className="label">Дата рождения:</div>
              <input
                type="date"
                placeholder="Дата рождения..."
                value={bookingState.birthDateISO}
                onChange={({target: {value}}) => setBookingState({...bookingState, birthDateISO: value})}
              />
            </div>
          </div>

          <hr />

          <div className="field-wrapper">
            <button disabled={bookingState.isPending}>
              {bookingState.isPending ? (
                <Loader color="#fff" size={25} width={3} spinsPerSecond={1.7} />
              ) : null}
              <span>Дальше</span>
            </button>
          </div>
        </form>
      ) : bookingState.step === 3 ? (
        <div className={styles.agreement}>
          <h2>Подпишите договор</h2>

          <div className={styles['agreement-canvas']} dangerouslySetInnerHTML={{__html: agreementHtml}}></div>

          <div className={styles['agreement-button-container']}>
            <button disabled={bookingState.isPending} onClick={() => createBooking()}>
              {bookingState.isPending ? (
                <Loader color="#fff" size={25} width={3} spinsPerSecond={1.7} />
              ) : null}
              <span>Я согласен с условиями</span>
            </button>
          </div>
        </div>
      ) : bookingState.step === 100 ? (
        <div className={styles.congrats}>
          <Icon icon="smiley-thin" />

          <div className="title">Всё готово!</div>

          <div className="description">
            Ждём вас у нас {DateTime.fromISO(bookingState.checkInAt).toFormat('D')} :)
          </div>
        </div>
      ) : null}

      <hr />

      <div className={styles.contacts}>
        <h2>Если возникли вопросы</h2>

        <a href="tel:+79771458011">
          <Icon icon="phone-2" />
          <span>+7 (977) 145-80-11</span>
        </a>

        <a href="mailto:buranhotel@yandex.ru">
          <Icon icon="email-4" />
          <span>buranhotel@yandex.ru</span>
        </a>
      </div>
    </div>
  );
}
