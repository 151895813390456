/* eslint-disable */
// noinspection HtmlUnknownTarget,JSUnusedLocalSymbols

import classNames from "classnames";
import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import BookingPage from "../../pages/booking";
import Icon from "../Icon";
import ModalWindow from "../ModalWindow";

import styles from "./index.module.css";

const Nav = () => (
  <nav>
    <a href="/#features-block">Преимущества</a>
    <a href="/#gallery-block">Фотогалерея</a>
    <a href="/#map-block">Где мы есть</a>
  </nav>
);

export default function App() {

  // State
  const [isHeaderBoxShadowed, setIsHeaderBoxShadowed] = useState(false);
  const [isAsideShown, setIsAsideShown] = useState<boolean>(false);

  // Hooks
  const $location = useLocation();
  const $navigate = useNavigate();

  // Generate color shades to CSS variables
  useEffect(() => {
    const shades = [
      10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700,
      800, 900, 1000
    ];

    const accentColorShades = [
      "#F6EDEB",
      "#EEDCD6",
      "#E5CAC2",
      "#DCB8AD",
      "#D4A799",
      "#CB9584",
      "#C28370",
      "#B9715B",
      "#B16047",
      "#A84E32",
      "#97462D",
      "#863E28",
      "#763723",
      "#652F1E",
      "#542719",
      "#431F14",
      "#32170F",
      "#22100A",
      "#110805"
    ];

    const blackColorShades = [
      "#F2F2F2",
      "#E6E6E6",
      "#D9D9D9",
      "#CCCCCC",
      "#C0C0C0",
      "#B3B3B3",
      "#A6A6A6",
      "#999999",
      "#8D8D8D",
      "#808080",
      "#737373",
      "#666666",
      "#5A5A5A",
      "#4D4D4D",
      "#404040",
      "#333333",
      "#262626",
      "#1A1A1A",
      "#0D0D0D"
    ];

    shades.forEach((shade, i) =>
      document.body.style.setProperty(
        `--accent-color-${shade}`,
        accentColorShades[i]
      )
    );

    shades.forEach((shade, i) =>
      document.body.style.setProperty(
        `--black-color-${shade}`,
        blackColorShades[i]
      )
    );
  }, []);

  // Handle header box shadow on scroll
  useEffect(() => {
    const handler: EventListener = () => {
      setIsHeaderBoxShadowed(window.scrollY > 1);
    };

    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  // Close mobile aside after location change
  useEffect(() => {
    setIsAsideShown(false);
  }, [$location]);

  // Render
  return (
    <div className={styles.app} style={{paddingTop: 50}}>
      {/* <header className={classNames({'is-box-shadowed': isHeaderBoxShadowed})}> */}
      {/*   <div className="container"> */}
      {/*     <div className="flex flex-aic"> */}
      {/*       <a href="/#welcome-block" style={{color: 'inherit'}} className="flex flex-aic"> */}
      {/*         /!* <img className={styles['header-logo']} src="./logo.svg" alt="" /> *!/ */}
      {/*         <h1 style={{marginLeft: 0}}>Buran Hotel</h1> */}
      {/*       </a> */}
      {/*     </div> */}
      {/*     /!* <Nav /> *!/ */}
      {/*     <div className="flex flex-jce" style={{marginLeft: 'auto'}}> */}
      {/*       /!* <button *!/ */}
      {/*       /!*   className={classNames("is-auto-width", styles['header-action-button'])} *!/ */}
      {/*       /!*   onClick={() => $navigate('/booking')} *!/ */}
      {/*       /!* >Забронировать *!/ */}
      {/*       /!* </button> *!/ */}
      {/*       /!* <button *!/ */}
      {/*       /!*   className={classNames("is-zeroed", styles['header-menu-button'])} *!/ */}
      {/*       /!*   onClick={() => setIsAsideShown(true)} *!/ */}
      {/*       /!* > *!/ */}
      {/*       /!*   <Icon icon="menu-right-lined" /> *!/ */}
      {/*       /!* </button> *!/ */}
      {/*     </div> */}
      {/*   </div> */}
      {/* </header> */}

      <Routes>
        <Route
          path="/booking"
          element={<BookingPage />}
        />
        <Route
          path=""
          element={<Navigate to="/booking" />}
        />
      </Routes>

      <footer>
        <div className="container">
          <div>ИП Попов С. К., ИНН: 770402101789, ОГРН: 318774600008888</div>
          <div>
            This site made with <Icon icon="heart-filled" /> by <a
            href="https://hoolie.org"
            target="_blank"
            rel="noreferrer"
          >Hoolie Projects</a>
          </div>
        </div>
      </footer>

      <div
        className={classNames(styles['aside-darker'], {'is-shown': isAsideShown})}
        onClick={() => setIsAsideShown(false)}
      />
      <div className={classNames(styles['mobile-aside'], {'is-shown': isAsideShown})}>
        <Nav />

        <button
          className={"is-auto-width"}
          style={{marginTop: 'auto'}}
          onClick={() => $navigate('/booking')}
        >Забронировать
        </button>
      </div>

      {/* Booking modal window */}
      <ModalWindow
        title="Забронировать капсулу"
        isShown={$location.search.includes('act=booking')}
        onClose={() => $navigate('?act=none')}
      >
        <div style={{textAlign: 'center'}}>
          Функция бронирования капсулы сейчас не доступна. Чтобы забронировать, <b>свяжитесь с нами:</b>
        </div>
        <div className={styles.contacts}>
          <a href="tel:+79771458011">
            <Icon icon="phone-2" />
            <span>+7 (977) 145-80-11</span>
          </a>

          <a href="mailto:buranhotel@yandex.ru">
            <Icon icon="email-4" />
            <span>buranhotel@yandex.ru</span>
          </a>
        </div>
      </ModalWindow>
    </div>
  );
}
